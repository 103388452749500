import { AcademicCapIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';

export interface LearnMoreHelpProps {
  description: string;
  link: string;
  iconOnly?: boolean;
}

export function LearnMoreHelp({
  description,
  link,
  iconOnly,
}: LearnMoreHelpProps) {
  if (iconOnly == true) {
    return (
      <a
        href={link}
        target="_blank"
        className="inline-flex justify-center gap-2 px-2 py-2 text-sm mt-2 font-medium text-black-200 bg-white  dark:bg-gray-900  dark:border-gray-700 border rounded-full hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
        rel="noreferrer"
      >
        <AcademicCapIcon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
      </a>
    );
  }
  return (
    <a
      href={link}
      target="_blank"
      className="inline-flex justify-center gap-2 px-4 py-2 text-sm mt-2 font-thin text-black dark:text-gray-200 bg-white dark:bg-gray-900 border  dark:border-gray-700 rounded-full hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
      rel="noreferrer"
    >
      <AcademicCapIcon className="w-5 h-5 text-gray-600 dark:text-gray-300" />
      <b>Learn:</b>
      {description}
    </a>
  );
}
